import React, { useEffect, useState } from 'react'
import { isEmailValid } from '@/utils/Helpers'
import { Button } from '@/components/ui/Buttons'
import { useRouter } from 'next/router'
import { imageLoader } from '@/utils/ImageLoaders'
import Image from 'next/image'
import { getCookie } from 'cookies-next'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { ComponentForm } from 'types/generated/contentful-types'
import {
  ForaConversionComponentNames,
  getReferralHistory,
  getURLParameters,
  trackConversionEvent,
} from 'analytics/ForaAnalytics'

enum FormState {
  INITIAL = 'initial',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type AdvisorFormProps = Pick<
  ComponentForm,
  'buttonText' | 'successText'
> & {
  inputValue?: string
  state?: string
  jotformId?: string
  conversionComponentName: ForaConversionComponentNames
}

export function NewsletterSignUpForm({
  inputValue,
  state,
  jotformId,
  buttonText,
  conversionComponentName,
  successText,
}: AdvisorFormProps): JSX.Element {
  const [pageReferralHistory] = useLocalStorage<string[]>('page-referrals', [])
  const [emailAddress, updateEmailAddress] = useState<string>(inputValue || '')
  const [formState, updateFormState] = useState<FormState>(FormState.INITIAL)
  const [validInput, setValidInput] = useState<boolean>(true)
  const isValidInput = isEmailValid(emailAddress)
  const router = useRouter()
  const [fbpCookie, fbcCookie, ttpCookie, hubspotCookie] = [
    getCookie('_fbp'),
    getCookie('_fbc'),
    getCookie('_ttp'),
    getCookie('hubspotutk'),
  ]

  useEffect(() => {
    if (state === 'success') updateFormState(FormState.SUCCESS)
    if (state === 'formError') updateFormState(FormState.ERROR)
    if (state === 'emailError') setValidInput(false)
  }, [state])

  const handleEmail = (value: string) => {
    setValidInput(true)
    updateEmailAddress(value)
  }

  const handleOnBlur = (value: string) => {
    if (value) setValidInput(isEmailValid(value))
  }

  const submitForm = () => {
    const { foraLastSlug1, foraLastSlug2, foraLastSlug3 } =
      getReferralHistory(pageReferralHistory)

    const {
      campaign,
      content,
      fbclid,
      gclid,
      medium,
      source,
      term,
      ttclid,
      msclkid,
    } = getURLParameters(router.query)

    fetch(`/api/forms/newsletter-sign-up`, {
      method: 'post',

      body: JSON.stringify({
        metadata: {
          utm: {
            source,
            medium,
            campaign,
            term,
            content,
          },
          fbclid,
          gclid,
          ttclid,
          msclkid,
          fbp: fbpCookie,
          fbc: fbcCookie,
          ttp: ttpCookie,
          hubspotutk: hubspotCookie,
          formPath: router.asPath,
        },
        jotformId,
        emailAddress,
      }),
    })
      .then((response) =>
        response.json().then((data) => {
          if (data.status === 'success' && response.status === 200) {
            updateFormState(FormState.SUCCESS)
            trackConversionEvent({
              componentName: conversionComponentName,
              foraFormEmail: emailAddress,
              fbc: fbcCookie?.toString() || '',
              fbp: fbpCookie?.toString() || '',
              hubspotCookie: hubspotCookie?.toString() || '',
              ttp: ttpCookie?.toString() || '',
              foraLastSlug1,
              foraLastSlug2,
              foraLastSlug3,
              fbclid,
              gclid,
              ip: data.request_ip,
              label: 'Newsletter Sign Up',
              ttclid,
              msclkid,
              pagePath: router.pathname,
            })
          } else {
            updateFormState(FormState.ERROR)
          }
        })
      )
      .catch(() => updateFormState(FormState.ERROR))
  }

  return (
    <div className="newsletter-form">
      <>
        <div>
          <label className="sr-only" htmlFor="newsletter-sign-up">
            Email Address
          </label>
        </div>
        <div className="flex">
          <input
            className={`w-full px-5 bg-transparent border  fora-text-button-2 normal-case ${
              !validInput ? 'border-red text-red' : ''
            }${formState === FormState.SUCCESS ? 'py-3' : 'border-r-0'}`}
            type="text"
            id="newsletter-sign-up"
            autoComplete="email"
            value={emailAddress}
            onChange={(event) => handleEmail(event.target.value)}
            placeholder="Enter email"
            onBlur={(event) => handleOnBlur(event.target.value)}
          />
          {formState !== FormState.SUCCESS && (
            <Button
              buttonFn={() => submitForm()}
              text={buttonText || 'Submit'}
              theme="primary"
              isDisabled={!isValidInput}
            />
          )}
        </div>
        {!validInput && (
          <div className=" my-2.5 error-message">
            <p>Invalid email address</p>
          </div>
        )}
        {formState === FormState.SUCCESS && (
          <div className="flex my-2.5 text-sm uppercase bg-shell py-1 px-5">
            <div className="mr-2.5 flex align-bottom">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 15,
                    quality: 90,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1727799161/icon-check_grrkf5.svg"
                alt="Icon Check"
                width={15}
                height={15}
              />
            </div>
            <p>{successText || 'Thank you! your submission has been sent.'}</p>
          </div>
        )}
        {formState === FormState.ERROR && (
          <div className="inline-block my-2.5 error-message">
            <p>There was an error. Please try again.</p>
          </div>
        )}
      </>
    </div>
  )
}
