import React, { useEffect, useRef, useState } from 'react'
import { isEmailValid } from '@/utils/Helpers'
import { Button } from '@/components/ui/Buttons'
import { useRouter } from 'next/router'
import { imageLoader } from '@/utils/ImageLoaders'
import Image from 'next/image'
import { getCookie } from 'cookies-next'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { ComponentForm } from 'types/generated/contentful-types'
import {
  ForaConversionComponentNames,
  getReferralHistory,
  getURLParameters,
  trackConversionEvent,
} from 'analytics/ForaAnalytics'

enum FormState {
  INITIAL = 'initial',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type AdvisorFormProps = Pick<ComponentForm, 'buttonText'> & {
  inputValue?: string
  state?: string
  jotformId?: string
  successMessage?: string
  typeformUrl?: string
  setShowTypeform?: any
  hasSubscriptionCard?: boolean
  isSticky?: boolean
  conversionComponentName: ForaConversionComponentNames
  typeformPopOutUrl?: string
}

export function AdvisorSignUpForm({
  inputValue,
  state,
  jotformId,
  buttonText,
  successMessage = 'Thanks! Check your inbox for a survey to get off the waitlist.',
  typeformUrl,
  setShowTypeform,
  hasSubscriptionCard = false,
  conversionComponentName,
  typeformPopOutUrl,
  isSticky = false,
}: AdvisorFormProps): JSX.Element {
  const [pageReferralHistory] = useLocalStorage<string[]>('page-referrals', [])
  const [emailAddress, updateEmailAddress] = useState<string>(inputValue || '')
  const [formState, updateFormState] = useState<FormState>(FormState.INITIAL)
  const [validInput, setValidInput] = useState<boolean>(true)
  const isValidInput = isEmailValid(emailAddress)
  const router = useRouter()
  const [fbpCookie, fbcCookie, ttpCookie, hubspotCookie] = [
    getCookie('_fbp'),
    getCookie('_fbc'),
    getCookie('_ttp'),
    getCookie('hubspotutk'),
  ]

  useEffect(() => {
    if (state === 'success') updateFormState(FormState.SUCCESS)
    if (state === 'formError') updateFormState(FormState.ERROR)
    if (state === 'emailError') setValidInput(false)
  }, [state])

  const handleEmail = (value: string) => {
    updateFormState(FormState.INITIAL)
    setValidInput(true)
    updateEmailAddress(value)
  }

  const handleOnBlur = (value: string) => {
    if (value) setValidInput(isEmailValid(value))
  }

  const {
    campaign,
    content,
    fbclid,
    gclid,
    medium,
    source,
    term,
    ttclid,
    msclkid,
    referral,
  } = getURLParameters(router.query)

  const submitForm = () => {
    if (!isValidInput) return
    const { foraLastSlug1, foraLastSlug2, foraLastSlug3 } =
      getReferralHistory(pageReferralHistory)

    fetch(`/api/forms/advisor-sign-up`, {
      method: 'post',
      body: JSON.stringify({
        metadata: {
          utm: {
            source,
            medium,
            campaign,
            term,
            content,
          },
          fbclid,
          gclid,
          ttclid,
          msclkid,
          fbp: fbpCookie,
          fbc: fbcCookie,
          ttp: ttpCookie,
          hubspotutk: hubspotCookie,
          formPath: router.asPath,
          fora_lastslug1: foraLastSlug1,
          fora_lastslug2: foraLastSlug2,
          fora_lastslug3: foraLastSlug3,
          referral,
        },
        jotformId,
        emailAddress,
      }),
    })
      .then((response) =>
        response.json().then((data) => {
          if (data.status === 'success' && response.status === 200) {
            updateFormState(FormState.SUCCESS)
            trackConversionEvent({
              foraFormEmail: emailAddress,
              componentName: conversionComponentName,
              fbc: fbcCookie?.toString() || '',
              fbp: fbpCookie?.toString() || '',
              hubspotCookie: hubspotCookie?.toString() || '',
              ttp: ttpCookie?.toString() || '',
              foraLastSlug1,
              foraLastSlug2,
              foraLastSlug3,
              fbclid,
              gclid,
              ip: data.request_ip,
              label: 'Advisor Sign Up',
              ttclid,
              msclkid,
              pagePath: router.pathname,
              referral,
            })
            if (typeformUrl) setShowTypeform(true)
          } else {
            updateFormState(FormState.ERROR)
          }
        })
      )
      .catch(() => updateFormState(FormState.ERROR))
  }

  const formatHiddenFieldsForTypeform = (baseURL: string) => {
    /*
     * Hidden fields must be configure in Typeform to accept each hidden field.
     * Format: https://foratravel.typeform.com/to/JFVqK8Ne?utm_source=xxxxx&...
     */
    const params = new URLSearchParams()

    const addParamIfString = (key: string, value: any) => {
      if (typeof value === 'string' && value.trim() !== '') {
        params.append(key, value)
      }
    }

    addParamIfString('referral', referral)
    addParamIfString('email', emailAddress)
    addParamIfString('utm_campaign', campaign)
    addParamIfString('utm_content', content)
    addParamIfString('utm_medium', medium)
    addParamIfString('utm_source', source)
    addParamIfString('utm_term', term)
    addParamIfString('ttclid', ttclid)
    addParamIfString('msclkid', msclkid)
    addParamIfString('fbclid', fbclid)
    addParamIfString('gclid', gclid)

    return `${baseURL}?${params.toString()}`
  }

  const BUTTON_TEXT = 'Get on the Waitlist'

  const stickyFormRef = useRef<HTMLDivElement>(null)
  const [isStickyForm, setIsStickyForm] = useState(false)

  useEffect(() => {
    const handleStickyChange = (entry: IntersectionObserverEntry) => {
      const isSticking = !entry.isIntersecting
      setIsStickyForm(isSticking)
    }

    // Observe when the hidden element has reached the top of the viewport
    const observer = new IntersectionObserver(
      (entries) => entries.forEach(handleStickyChange),
      { rootMargin: '-17px 0px 0px 0px', threshold: 1 }
    )
    const stickyFormEl = stickyFormRef.current
    if (stickyFormEl) observer.observe(stickyFormEl)
    return () => {
      if (stickyFormEl) observer.unobserve(stickyFormEl)
    }
  }, [stickyFormRef])

  return (
    <div
      ref={isSticky ? stickyFormRef : null}
      className={`${isStickyForm ? 'sticky-container' : ''} ${
        isSticky ? 'lg:w-max w-full lg:mx-auto' : ''
      }`}
    >
      <div
        className={`${isStickyForm ? 'sticky-input' : ''} ${
          !validInput || formState === FormState.ERROR ? 'error-state' : ''
        }
        ${formState === FormState.SUCCESS ? 'success-state' : ''}
        ${
          isSticky
            ? 'md:flex md:flex-row justify-center md:h-12 md:w-max mx-auto'
            : ''
        }`}
      >
        <div className={`${isSticky ? 'md:w-auto w-full relative' : 'mb-6'}`}>
          <input
            className={`w-full
              ${
                isSticky && formState === FormState.SUCCESS
                  ? 'hidden md:block'
                  : ''
              }
              ${
                isSticky
                  ? 'bg-sand md:w-80 p-4 max-h-10 md:max-h-12'
                  : 'p-5 bg-transparent'
              } placeholder-darkStone outline-none border fora-text-button-2 normal-case ${
              !validInput ? 'border-red text-red' : ''
            }`}
            type="text"
            id="advisor-sign-up"
            autoComplete="email"
            value={emailAddress}
            onChange={(event) => handleEmail(event.target.value)}
            onBlur={(event) => handleOnBlur(event.target.value)}
            placeholder={
              isSticky ? 'Enter your email address to apply' : '*EMAIL ADDRESS'
            }
          />
          {isSticky && formState !== FormState.SUCCESS && (
            <div
              className="submission-arrow-mobile"
              onClick={() => submitForm()}
              onKeyDown={() => submitForm()}
              role="button"
              tabIndex={0}
            >
              <Image
                src="https://media.foratravel.com/image/upload/v1731095433/submit-arrow-r_zzobaw.svg"
                alt="submit-icon"
                width={16}
                height={13}
                priority
              />
            </div>
          )}
          {!validInput && (
            <div
              className={`error-message ${
                isSticky
                  ? 'md:text-14 text-12 normal-case !mt-2'
                  : 'my-2.5 uppercase'
              }`}
            >
              <p className="input-message">Invalid email address</p>
            </div>
          )}
          {formState === FormState.SUCCESS && !typeformUrl && (
            <div
              className={`flex text-sm justify-center ${
                isSticky
                  ? 'rounded-[8px] bg-darkSand md:bg-transparent p-2 md:p-0 md:justify-start md:mt-2'
                  : 'uppercase bg-shell my-2.5 px-5 py-1'
              }`}
            >
              {!isSticky && (
                <div className="mr-2.5 flex align-bottom">
                  <Image
                    loader={({ src }) =>
                      imageLoader({
                        src: src,
                        width: 15,
                        quality: 90,
                      })
                    }
                    src="https://media.foratravel.com/image/upload/v1727799161/icon-check_grrkf5.svg"
                    alt="Icon Check"
                    width={15}
                    height={15}
                  />
                </div>
              )}
              <p className="input-message">
                {successMessage || 'Thank you! your submission has been sent.'}
              </p>
            </div>
          )}
          {formState === FormState.ERROR && (
            <div
              className={`error-message ${
                isSticky
                  ? '!text-12 normal-case w-[295px] md:w-max block mt-2'
                  : 'my-2.5 inline-block '
              }`}
            >
              <p className="input-message">
                There was an error. Please try again.
              </p>
            </div>
          )}
        </div>
        {(formState !== FormState.SUCCESS || isSticky) && (
          <div
            className={`${
              formState === FormState.SUCCESS && isSticky ? 'hidden' : ''
            } ${
              isSticky
                ? 'md:w-40 w-full whitespace-nowrap md:block mt-2 md:mt-0'
                : ''
            } ${hasSubscriptionCard ? '' : 'text-center'}`}
          >
            {typeformPopOutUrl ? (
              // Prevents opening new tab from being blocked in Safari
              <a
                href={formatHiddenFieldsForTypeform(typeformPopOutUrl)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  buttonFn={() => submitForm()}
                  text={buttonText || BUTTON_TEXT}
                  theme="primary"
                  isFull={!hasSubscriptionCard}
                  isDisabled={!isValidInput}
                />
              </a>
            ) : (
              <Button
                buttonFn={() => submitForm()}
                text={buttonText || BUTTON_TEXT}
                theme={isSticky ? 'dark' : 'primary'}
                isFull={!hasSubscriptionCard}
                isDisabled={!isValidInput}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
