import Link from 'next/link'
import { imageLoader } from '@/utils/ImageLoaders'
import Image from 'next/image'
import { NextRouter, useRouter } from 'next/router'

export type PaginationProps = {
  pageUrl?: string
  queryString?: { [key: string]: string | string[] | undefined }
  totalPages: string
  currentPage: string
  prevDisabled: boolean
  nextDisabled: boolean
  type?: string
  onPreviousClick?: () => void
  onNextClick?: () => void
  goToPage?: (page: number) => void
  setNewCurrentSlide?: (newCurrentSlide: number) => any
  showArrows?: boolean
  shallowRenderPages?: boolean
}

export default function Pagination({
  pageUrl,
  queryString,
  totalPages,
  currentPage,
  prevDisabled,
  nextDisabled,
  type,
  onPreviousClick,
  onNextClick,
  goToPage,
  setNewCurrentSlide,
  showArrows = true,
  shallowRenderPages = false,
}: PaginationProps) {
  const router = useRouter()

  const pageQueryString = queryString
    ? Object.keys(queryString)
        .filter((q) => q !== 'p')
        .map((q) => `${q}=${queryString[q]}`)
        .join('&')
    : ''

  const prevPageUrl = `/${pageUrl}${type ? `/${type}` : ''}?p=${
    parseInt(currentPage) - 1
  }${pageQueryString.length > 0 ? `&${pageQueryString}` : ''}`

  const nextPageUrl = `/${pageUrl}${type ? `/${type}` : ''}?p=${
    parseInt(currentPage) + 1
  }${pageQueryString.length > 0 ? `&${pageQueryString}` : ''}`

  const prevPage = (parseInt(currentPage) - 1).toString()
  const nextPage = (parseInt(currentPage) + 1).toString()

  const buildPageUrl = (page: string) => {
    const baseUrl = `/${pageUrl}${type ? `/${type}` : ''}`
    const queryParams = { ...queryString, p: page }
    return { pathname: baseUrl, query: queryParams }
  }

  const handleShallowRoute = (page: string) => {
    router.push(buildPageUrl(page), undefined, { shallow: true })
  }

  const onPrev = () => {
    if (onPreviousClick) onPreviousClick()
    if (setNewCurrentSlide) setNewCurrentSlide(parseInt(currentPage) - 1)
    if (shallowRenderPages && !prevDisabled) handleShallowRoute(prevPage)
  }

  const onNext = () => {
    if (onNextClick) onNextClick()
    if (setNewCurrentSlide) setNewCurrentSlide(parseInt(currentPage) + 1)
    if (shallowRenderPages && !nextDisabled) handleShallowRoute(nextPage)
  }

  const onToPage = (page: string) => {
    if (goToPage) goToPage(parseInt(page))
    if (setNewCurrentSlide) setNewCurrentSlide(parseInt(page))
    if (shallowRenderPages) handleShallowRoute(page)
  }

  const toPage = (page: string) => {
    return `/${pageUrl}${type ? `/${type}` : ''}?p=${page}${
      pageQueryString.length > 0 ? `&${pageQueryString}` : ''
    }`
  }

  const pages: string[] = []
  for (let page = 1; page <= parseInt(totalPages); page++) {
    pages.push(page.toString())
  }

  const renderPageNumbers = () => {
    const total = parseInt(totalPages)
    const current = parseInt(currentPage)
    let pagesToDisplay: (number | string)[] = []

    if (total <= 7) {
      // Display all pages
      pagesToDisplay = Array.from({ length: total }, (_, i) => i + 1)
    } else {
      pagesToDisplay.push(1)

      if (current <= 4) {
        pagesToDisplay.push(2, 3, 4, 5)
        pagesToDisplay.push('ellipsis')
      } else if (current >= total - 3) {
        pagesToDisplay.push('ellipsis')
        pagesToDisplay.push(total - 4, total - 3, total - 2, total - 1)
      } else {
        pagesToDisplay.push('ellipsis')
        pagesToDisplay.push(current - 1, current, current + 1)
        pagesToDisplay.push('ellipsis')
      }

      pagesToDisplay.push(total)
    }

    return pagesToDisplay.map((page, index) => {
      if (page === 'ellipsis') {
        return (
          <span key={`ellipsis-${index}`} className="flex items-center">
            <Image
              className="align-bottom"
              loader={({ src }) =>
                imageLoader({
                  src: src,
                  width: 10,
                  quality: 90,
                })
              }
              src="https://media.foratravel.com/image/upload/v1727438834/dots_h6ombf.svg"
              alt="Ellipsis"
              width={10}
              height={4}
            />
          </span>
        )
      } else {
        const href = shallowRenderPages
          ? shallowRenderHref({
              page: page.toString(),
              router,
              currentPage: parseInt(currentPage),
            })
          : null

        return (
          <PaginationButtonNumber
            key={`PageIndex_${page}`}
            page={page.toString()}
            currentPage={currentPage}
            toPage={goToPage ? onToPage : toPage}
            shallowRenderHref={href}
            router={router}
          />
        )
      }
    })
  }

  return (
    <ol className="flex justify-center">
      {showArrows && (
        <li className="mr-4">
          {onPreviousClick ? (
            <div
              className={`${
                prevDisabled ? 'cursor-default' : 'cursor-pointer'
              }`}
              onClick={!prevDisabled ? onPrev : undefined}
              onKeyDown={!prevDisabled ? onPrev : undefined}
              role="button"
              tabIndex={0}
            >
              <PaginationButton disable={prevDisabled} side="left" />
            </div>
          ) : !prevDisabled ? (
            <Link
              href={
                shallowRenderPages
                  ? shallowRenderHref({
                      type: 'prev',
                      currentPage: parseInt(currentPage),
                      router,
                    })
                  : prevPageUrl
              }
            >
              <PaginationButton disable={prevDisabled} side="left" />
            </Link>
          ) : (
            <PaginationButton disable={prevDisabled} side="left" />
          )}
        </li>
      )}
      <span className="flex gap-4">{renderPageNumbers()}</span>
      {showArrows && (
        <li className="ml-4">
          {onNextClick ? (
            <div
              className={`${
                nextDisabled ? 'cursor-default' : 'cursor-pointer'
              }`}
              onClick={!nextDisabled ? onNext : undefined}
              onKeyDown={!nextDisabled ? onNext : undefined}
              role="button"
              tabIndex={0}
            >
              <PaginationButton disable={nextDisabled} side="right" />
            </div>
          ) : !nextDisabled ? (
            <Link
              href={
                shallowRenderPages
                  ? shallowRenderHref({
                      type: 'next',
                      currentPage: parseInt(currentPage),
                      router,
                    })
                  : nextPageUrl
              }
              shallow={shallowRenderPages}
            >
              <PaginationButton disable={nextDisabled} side="right" />
            </Link>
          ) : (
            <PaginationButton disable={nextDisabled} side="right" />
          )}
        </li>
      )}
    </ol>
  )
}

type ShallowRenderHref = {
  pathname: string
  query: any
}

type ShallowRenderHrefProps = {
  type?: 'prev' | 'next'
  currentPage: number
  router: NextRouter
  page?: string
}

const shallowRenderHref = ({
  type,
  currentPage,
  router,
  page,
}: ShallowRenderHrefProps): ShallowRenderHref => {
  const href = {
    pathname: router.pathname,
    query: { ...router.query },
  }

  if (page) {
    href.query.p = page
    return href
  }

  const toPage = type === 'prev' ? currentPage - 1 : currentPage + 1
  href.query.p = toPage.toString()
  return href
}

type PaginationButtonNumberProps = {
  page: string
  currentPage: string
  toPage: any
  router: NextRouter
  shallowRenderHref?: ShallowRenderHref | null
}

function PaginationButtonNumber({
  currentPage,
  page,
  toPage,
  router,
  shallowRenderHref = null,
}: PaginationButtonNumberProps) {
  const navTo = (page) => {
    router.push(toPage(parseInt(page)))
  }

  return (
    <>
      {shallowRenderHref ? (
        <Link href={shallowRenderHref} shallow={true}>
          <li className={`${currentPage == page ? 'underline' : ''}`}>
            {page}
          </li>
        </Link>
      ) : (
        <div
          className="cursor-pointer"
          onClick={() => navTo(page)}
          onKeyDown={() => navTo(page)}
          role="button"
          tabIndex={0}
        >
          <li className={`${currentPage == page ? 'underline' : ''}`}>
            {page}
          </li>
        </div>
      )}
    </>
  )
}

type PaginationButtonProps = {
  side: 'left' | 'right'
  disable?: boolean
}

export function PaginationButton({
  side,
  disable = false,
}: PaginationButtonProps) {
  return (
    <div
      className={`flex justify-center w-6 h-6 border rounded-full ${
        // eslint-disable-next-line prettier/prettier
        disable ? 'opacity-0' : ''
      }`}
    >
      <Image
        className="align-bottom"
        loader={({ src }) =>
          imageLoader({
            src: src,
            width: 10,
            quality: 90,
          })
        }
        src={
          side === 'right'
            ? 'https://media.foratravel.com/image/upload/v1727798794/icon-arrow-right_ryvkvs.svg'
            : 'https://media.foratravel.com/image/upload/v1727798569/icon-arrow-left_gickbd.svg'
        }
        alt={side === 'right' ? 'Icon Arrow Right' : 'Icon Arrow Left'}
        width={10}
        height={10}
      />
    </div>
  )
}
